import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import SectionTitle from "@ui/section-title";
import {
  TrQualityCriteriaWrapper,
  TrQualityCriteriaInner
} from "./style";

export default function TranslationQualityCriteria({
  headingStyle,
  data,
  layout, 
  ...props
}) {
  return (
    <TrQualityCriteriaWrapper layout={layout} {...props} id={data.section}>
      <TrQualityCriteriaInner>
        <Container>
          <Row>
            <Col lg={12}>
              <SectionTitle
              layout={1}
                subtitle={data.section_title.subtitle}
                title={data.section_title.title}
                subheading={data.headings[0].content}
                description={data.section_title.description}
              />
            </Col>
          </Row>
          {/* <Row>
            <Col>
              <TrQualityCriteriaBox>
                {!!data.items &&
                  data.items.map((el, index) => (
                    <TrQualityCriteriaBoxInner key={index}>
                      <TrQualityCriteriaIconwrap>
                        <Image src={el.images[0].src} alt="high" />
                      </TrQualityCriteriaIconwrap>
                      <TrQualityCriteriaTextwrap>
                        <Heading {...headingStyle}>{el.title}</Heading>
                        <TrQualityCriteriaList>
                          {el.list.map((data, index1) => (
                            <TrQualityCriteriaItem key={index1}>
                              {" "}
                              {data.text}
                            </TrQualityCriteriaItem>
                          ))}
                        </TrQualityCriteriaList>
                      </TrQualityCriteriaTextwrap>
                    </TrQualityCriteriaBoxInner>
                  ))}
              </TrQualityCriteriaBox>
            </Col>
          </Row> */}
        </Container>
      </TrQualityCriteriaInner>
    </TrQualityCriteriaWrapper>
  );
}
TranslationQualityCriteria.propTypes = {
  textStyle: PropTypes.object,
  headingStyle: PropTypes.object,
};

TranslationQualityCriteria.defaultProps = {
  headingStyle: {
    as: "h4",
    color: "#fff",
    fontSize: "22px",
    fontweight: 700,
  },
  subheadingStyle: {
    as: "h6",
    color: "#fff",
    fontSize: "15px",
    fontweight: 400,
  }
};
